@import "theme.css";
@import "general.css";
@import "components/index.css";

.subscription-modal {
    width: 300px; /* Adjust the width as per your requirements */
    height: auto; /* You can set a fixed height if needed */
    padding: 20px; /* Adjust padding as needed */
  }
  
  text-white {
    color: #ffffff;
  }
  
  /* AccordionStyles.css */
.custom-accordion {
  background-color: black;
  color: white;
  font-size: 1.6rem;
}

.custom-accordion .accordion-header {
  background-color: black;
  color: white;
  font-size: 1.6rem;
}

.custom-accordion .accordion-body {
  background-color: black;
  color: white;
  font-size: 1.6rem;
}
.custom-accordion .accordion-button {
  background-color: black;
  color: white;
  font-size: 1.6rem;
}

black {
  color: black;
}
fs-1 {
  font-size: 1rem;
}